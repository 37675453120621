import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/tailwindcssOutput.css'
import "@/assets/css/normalize.css";
import "@/assets/css/style.scss";
import moment from "moment/moment";
import "moment/locale/zh-cn";
import Vodal from "vodal";
import "vodal/common.css";
import "vodal/zoom.css";
import "vodal/rotate.css";
// 全局挂在echarts实例
Vue.prototype.$echarts = require('echarts')
Vue.config.productionTip = false;
// 全局引入vodal插件
Vue.component(Vodal.name, Vodal);
// 挂在store
Vue.prototype.$store = store;
moment.locale("zh-cn");
Vue.prototype.$moment = moment;
// 使用api接口
Vue.use(api);
Vue.use(ElementUI);
Vue.prototype.$EventBus = new Vue();
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
