import qs from "qs";
import http from "../../common/http";
export function searchList(data) {
  return http.get(`/index/searchList?year=` + data.year + `&corp_group=` + data.corp_group + `&item=` + data.item + `&type=` + data.type);
}

export function radarChart(data) {
  return http.get(`/index/radarChart?year=${data.year}&corp_group=${data.corp_group}&item=${data.item}&country=${data.country}&country2=${data.country2}`);
}

export function boxChart(data) {
  return http.get(`/index/boxChart?type=${data.type}&year=${data.year}&corp_group=${data.corp_group}&item=${data.item}&region_1=${data.region_1}&region_2=${data.region_2}&income_level=${data.income_level}`);
}
export function box1(data) {
  return http.get(`/index/box1?type=${data.type}&year=${data.year}&corp_group=${data.corp_group}&item=${data.item}&item1=${data.item1}`);
}

export function box2(data) {
  return http.get(`/index/box2?type=${data.type}&year=${data.year}&corp_group1=${data.corp_group1}&corp_group2=${data.corp_group2}&item=${data.item}`);
}