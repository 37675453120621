<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState({}),
  },
};
</script>

<style></style>
