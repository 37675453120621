import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
// 使用vuex
Vue.use(Vuex);
// 部署环境 测试
let deployEnv = 'test'
// 部署环境 正式
// deployEnv = 'pro'

const store = new Vuex.Store({
  state: {
    // '「申请的安全密钥」',
    securityJsCode: "4102d8d568c41c6af9ec1c4eb422d21f",
    // 地图key
    mapKey: "05fdb6e32b63e2534ef2351b8a26d067",
    // 部署环境
    deployEnv,
    // token
    token: localStorage.getItem("token") || "",
    // 用户信息
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {},
  },
  actions: {
    login({ commit }, info) {
      // 这里可以是API请求，登录成功后提交mutation
      commit("login", info);
    },
    logout({ commit }) {
      // 这里可以是API请求，登出成功后提交mutation
      commit("logout");
    },
  },
  mutations: {
    // 登录
    login(state, info) {
      state.token = info.token;
      state.userInfo = info.user_info;
      localStorage.setItem("token", info.token);
      localStorage.setItem("userInfo", JSON.stringify(info.user_info));
      setTimeout(() => {
        router.go(-1);
      }, 50);
    },
    // 退出登录
    logout(state) {
      state.token = "";
      state.userInfo = {};
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      router.push({
        name: "Login",
      });
    },
  },
  getters: {},
});

function getHashQueryParams() {
  // 获取哈希部分后面的查询字符串
  const hashIndex = window.location.hash.indexOf('?');
  const queryString = hashIndex !== -1 ? window.location.hash.substring(hashIndex + 1) : '';

  const params = new URLSearchParams(queryString);
  const queryParams = {};

  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
}

export default store;
