import Vue from "vue";
import axios from "axios";
import store from "@/store";
let baseURL = "https://api.green-ag.org/index";
if (store.state.deployEnv == 'pro') {
}
let service = axios.create({
  baseURL,
  timeout: 1000 * 300,
});

service.interceptors.request.use((config) => {
  // if (store.state.token) {
  //   if (config.url.indexOf("login") == -1) {
  //     config.headers["Authorization"] = `Bearer ${store.state.token}`;
  //   }

  //   if (config.method == "post") {
  //     //判断是不是上传图片接口
  //     // config.data = qs.stringify(config.data)
  //   }
  //   if (config.url.indexOf("history_pipe") != -1) {
  //     console.log(config.url);
  //     // config.headers['Cache-Control'] = 'no-cache';
  //   } else {
  //     // config.headers['Cache-Control'] = 'no-store';
  //   }
  // } else {
  //   store.dispatch("logout");
  // }

  return config;
});
service.interceptors.response.use((response) => {
  // 请求后封装
  if (response.status === 200) {
    if (response.data.code == 401) {
      store.dispatch("logout");
    }

    return response;
  } else {
    return Promise.reject({
      msg: response.data.msg,
      data: response.data,
    });
  }
});

export default service;
