import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch((err) => { });
};
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "Page", //
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/index.vue"),
    hidden: true,
    meta: {
      keepAlive: true, // 添加 keep-alive 的 meta 属性
      title: "用户登录",
    },
  },
  {
    path: "/page",
    name: "Page",
    component: () => import("@/views/Page/index.vue"),
    redirect: "/Page/home",
    children: [
      {
        path: "home",
        name: "PageHome",
        component: () => import("@/views/Page/Home/index.vue"),
        hidden: true,
        meta: {
          keepAlive: true,
          title: "首页",
        },
      },
      {
        path: "people",
        name: "People",
        component: () => import("@/views/Page/People/index.vue"),
        hidden: true,
        meta: {
          keepAlive: true,
          title: "朋友",
        },
      },
      {
        path: "meetingList",
        name: "MeetingList",
        component: () => import("@/views/Page/MeetingList/index.vue"),
        hidden: true,
        meta: {
          keepAlive: true,
          title: "会议列表",
        },
      },
      {
        path: "meetingDesc",
        name: "MeetingDesc",
        component: () => import("@/views/Page/MeetingDesc/index.vue"),
        hidden: true,
        meta: {
          keepAlive: true,
          title: "会议详情",
        },
      },
      {
        path: "researchList",
        name: "ResearchList",
        component: () => import("@/views/Page/ResearchList/index.vue"),
        hidden: true,
        meta: {
          keepAlive: true,
          title: "研究成果列表",
        },
      },
      {
        path: "researchDesc",
        name: "ResearchDesc",
        component: () => import("@/views/Page/ResearchDesc/index.vue"),
        hidden: true,
        meta: {
          keepAlive: true,
          title: "研究成果详情",
        },
      },

    ]
  },

];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

// router.beforeEach((to, from, next) => {
//   let isLogin = store.state.token; // 这里应该是一个判断用户是否登录的逻辑，例如检查本地存储或者cookie
//   if (to.path !== "/login" && !isLogin) {
//     next("/login"); // 如果用户未登录且尝试访问非登录页面，重定向到登录页
//   } else {
//     next(); // 否则，正常导航
//   }
// });

export default router;
